import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import EchartsRunPie from "./pie/pie.vue";
import EchartsMap from "./map/map.vue";
import WebSocketService from "@/websocket/WebSocketService";
import MessageRequest from "@/websocket/MessageRequest";
import MessageResponse from "@/websocket/MessageResponse";
import { WebSocketMonitorUrl } from "@/utils/axios";
import store from "@/store";

@Options({
    name: "app-monitor-dashboard-running-state",
    components: {
        EchartsRunPie,
        EchartsMap,
    },
})
export default class RunningState extends BaseFunction {
    public currentDateTime = "";
    public timer: number; // 用于存储 setInterval 的 ID
    public wbSocket: WebSocketService<MessageRequest, MessageResponse>;
    // public currentTableTitle = "全部异常";
    // public currentTableData: any[] = [];
    // public currentTableCount = 0;
    // public currentTableIndex = 1;
    public loading = false;
    public tableLabelArray: string[] = ["全部异常", "重大异常", "故障信息", "告警信息"];
    public eChartData: any = {
        isBox: false,
        workJsonList: [],
        mapName: "速泰智能",
        work: "100000",
        currentTableTitle: "全部异常",
        currentTableData: [],
        currentTableCount: 0,
        currentTableIndex: 1,
        runPieData: {},
        boxMapData: [],
        statusMapData: [],
        statusTableData: [],
        workOrder: [],
        monitorMessageCount: 0,
        monitorMessageTopList: [],
        messageTypeData: {
            warning: [],
            failure: [],
        },
    };

    public onToggleMenuAndHeader(): void {
        this.$store.dispatch("ui/toggleMenuAndHeader");
    }

    get menuAndHeaderCollapsed() {
        return this.$store.getters["ui/menuAndHeaderCollapsed"];
    }

    // 重大异常
    get dangerMessage() {
        if (!this.eChartData.statusTableData) return [];
        return this.eChartData.statusTableData.filter((obj: any) => obj.level === "L5");
    }

    get dangerMessageCount() {
        if (!this.dangerMessage) return 0;
        return this.dangerMessage.length;
    }

    // 故障信息
    get failureMessage() {
        if (!this.eChartData.statusTableData) return [];
        return this.eChartData.statusTableData.filter((obj: any) => obj.messageType === "failure");
    }

    get failureMessageCount() {
        if (!this.warningMessage) return 0;
        return this.failureMessage.length;
    }

    // 告警信息
    get warningMessage() {
        if (!this.eChartData.statusTableData) return [];
        return this.eChartData.statusTableData.filter((obj: any) => obj.messageType === "warning");
    }

    get warningMessageCount() {
        if (!this.warningMessage) return 0;
        return this.warningMessage.length;
    }

    // 切换表格数据
    public changeTable(index: number) {
        this.eChartData.currentTableIndex = index;
        this.eChartData.currentTableTitle = this.tableLabelArray[index];

        if (index === 1) {
            this.eChartData.currentTableData = this.dangerMessage;
            this.eChartData.currentTableCount = this.dangerMessageCount;
        } else if (index === 2) {
            this.eChartData.currentTableData = this.failureMessage;
            this.eChartData.currentTableCount = this.failureMessageCount;
        } else if (index === 3) {
            this.eChartData.currentTableData = this.warningMessage;
            this.eChartData.currentTableCount = this.warningMessageCount;
        } else {
            if (this.eChartData.statusTableData) {
                this.eChartData.currentTableData = this.eChartData.statusTableData;
                this.eChartData.currentTableCount = this.eChartData.currentTableData.length;
            } else {
                this.eChartData.currentTableData = [];
                this.eChartData.currentTableCount = 0;
            }
        }

        if (!this.eChartData.isBox) (this.$refs.echartsMap as any).refreshData();
    }

    public unmounted(): void {
        this.closeWebSocket();
        if (this.timer) clearInterval(this.timer);
    }
    public destroyed() {
        this.closeWebSocket();
        if (this.timer) clearInterval(this.timer);
    }
    public deactivated() {
        this.closeWebSocket();
        if (this.timer) clearInterval(this.timer);
    }

    public closeWebSocket() {
        if (!this.wbSocket) return;
        if (!this.wbSocket.options) return;
        if (!this.wbSocket.options.isDestroy) this.wbSocket.destroy();
    }

    public activated() {
        this.$nextTick(async () => {
            //开始时钟
            this.startTime();
            //连接websocket
            await this.openWebSocket();
        }).then();
    }

    public startTime() {
        this.timer = setInterval(() => {
            const now = new Date();
            this.currentDateTime = this.formatDateTime(now);
        }, 1000);
    }

    public formatDateTime(date: Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    //连接websocket
    public async openWebSocket() {
        this.loading = true;
        this.wbSocket = new WebSocketService<MessageRequest, MessageResponse>({
            url: WebSocketMonitorUrl,
            style: "monitor",
            work: "100000",
            openCb: (e) => {
                // 连接成功回调
                // console.log("连接成功：", e);
                // this.infoList = [];
            },
            closeCb: (e) => {
                // 连接关闭回调
                // console.log("连接关闭：", e);
                this.loading = false;
            },
            messageCb: (info: MessageResponse) => {
                this.loading = false;
                // 消息回调
                if (info.code == 200) {
                    console.log("接收到消息", info.data);
                    this.eChartData.isBox = info.data.isBox;
                    this.eChartData.work = info.data.work;
                    this.eChartData.workJsonList = info.data.workJsonList;
                    this.eChartData.projectTotal = info.data.projectTotal;
                    this.eChartData.runPieData = info.data.runPieData;
                    this.eChartData.boxMapData = info.data.boxMapData;
                    this.eChartData.statusMapData = info.data.statusMapData;
                    this.eChartData.statusTableData = info.data.statusTableData;
                    this.eChartData.workOrder = info.data.workOrder;
                    this.eChartData.messageTypeData = info.data.messageTypeData;
                    this.eChartData.monitorMessageCount = info.data.monitorMessageCount;
                    this.eChartData.monitorMessageTopList = info.data.monitorMessageTopList;
                    (this.$refs.echartsRunPie as any).refreshData();
                    this.changeTable(this.eChartData.currentTableIndex);
                    // if (!this.eChartData.isBox) (this.$refs.echartsMap as any).refreshData();
                } else {
                    console.log("接收到错误消息", info);
                    this.toast.error(info.msg);
                    return;
                }
            },
            errorCb: (e) => {
                // 错误回调
                //console.log("连接错误：", e);
                this.loading = false;
            },
        });
    }

    get mapGeoData(): any {
        if (!this.$refs.echartsMap) return {};
        return (this.$refs.echartsMap as any).getGeoData();
    }

    //地图下钻事件
    public mapDrillDown(res: any) {
        const mapProperties = this.nameToMapProperties(res.name, this.mapGeoData.adjson);
        const work = `${this.eChartData.work}.${mapProperties.adcode}`;
        this.reloadMap(mapProperties.name, mapProperties.adcode, work);
    }
    public reloadMap(name: string, adcode: number, work: string) {
        (this.$refs.echartsMap as any).reloadMap(name, adcode, () => {
            this.wbSocket.setWork("monitor", work);
            this.wbSocket.send(<MessageRequest>{
                style: "monitor",
                work: work,
                type: 2,
                send: store.getters["auth/userInfo"].userId,
            });
        });
    }
    public nameToMapProperties(adname: any, adjson: any): any {
        const item: any = adjson.features.find((features: any) => features.properties.name === adname);
        return item.properties;
    }
}
