import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import ProjectModelAttributeItem from "@/entity/project/ProjectModelAttributeItem";
import ProjectModelTableItem from "@/entity/project/ProjectModelTableItem";
import { AttributeScopeOptions, AttributeTypeOptions, ModelObjectOptions } from "@/entity/project/ProjectControl";
@Options({
    name: "app-project-model-attribute-tr",
    props: {
        modelValue: {
            type: [ProjectModelAttributeItem, Object],
            default: (): ProjectModelAttributeItem => null,
        },
        index: {
            type: Number,
            default: (): number => 0,
        },
        node: {
            type: [ProjectModelTableItem, Object],
            default: (): ProjectModelTableItem => null,
        },
    },
    emits: ["update:modelValue", "ActionEvent"],
})
export default class ModelAttributeTr extends BaseFunction {
    private modelValue!: ProjectModelAttributeItem;

    get attribute() {
        return this.modelValue;
    }
    set attribute(val) {
        this.$emit("update:modelValue", val);
    }

    public attributeTypeName(value: string) {
        return AttributeTypeOptions.find((item: any) => item.value === value)?.label;
    }
    public attributeScopeName(value: string) {
        return AttributeScopeOptions.find((item: any) => item.value === value)?.label;
    }

    public modelObjectName(value: string) {
        return ModelObjectOptions.find((item: any) => item.value === value)?.label;
    }

    //填充层级
    public fillTree(parentStr: string, str: string): string {
        // const matches = parentStr.match(/-/g);
        // const count = matches ? matches.length : 0;
        // const level = parentStr.length < 1 ? 0 : count + 1;
        // const fgf = "-".repeat(level * 2);
        // return `|--${fgf}${str}`;
        if (this.$tools.isEmpty(parentStr)) {
            return str;
        } else {
            return `${parentStr}-${str}`;
        }
    }

    //动作事件
    public actionEvent(action: string, node: ProjectModelTableItem, index: number, event: any) {
        this.$emit("ActionEvent", action, node, index, event);
    }
}
