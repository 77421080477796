
import { Options } from "vue-class-component";
import ComponentMixins from "../ComponentMixins";

@Options({
    name: "flowable-common-form-components-description",
    props: {
        color: {
            type: String,
            default: "#868686",
        },
        placeholder: {
            type: String,
            default: "只是一段说明文字",
        },
    },
})
export default class Description extends ComponentMixins {}
