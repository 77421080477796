import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import StarTableItem from "@/entity/aftersale/ticket/StarTableItem";
import API from "@/api/aftersale/ticket/star";
import BusinessApi from "@/api/aftersale/ticket/business";
import InfluenceApi from "@/api/aftersale/ticket/influence";

@Options({
    name: "app-aftersale-ticket-star",
    components: {
        "app-select-level": SelectLevel,
    },
})
export default class Star extends BaseTableMixins<StarTableItem> implements Partial<BaseTableInterface<StarTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new StarTableItem();
        this.editModalItem = new StarTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            businessId: {
                type: "router",
                name: "business_id",
                title: "故障类型",
                parent: -1,
                value: null,
                openFn: this.businessIdLevelModalOpen,
            },
            influenceId: {
                type: "router",
                name: "influence_id",
                title: "影响范围",
                parent: -1,
                value: null,
                openFn: this.influenceIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    get getBusinessApi() {
        return BusinessApi;
    }

    get getInfluenceApi() {
        return InfluenceApi;
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<StarTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new StarTableItem();
        this.editModalItem = new StarTableItem();
        this.editModalItem.starNum = "3";
        this.editModalItem.starStatus = 1;
        this.editModalTitle = "添加工单星级";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.starStatus)) return "星级状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.businessId)) return "故障类型不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.influenceId)) return "影响范围不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.starNum)) return "星级不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改工单星级 (" + this.editModalItem.businessIdName + "/" + this.editModalItem.influenceIdName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.starId)) return "工单星级ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除工单星级",
            message: this.editModalItem.businessIdName + "/" + this.editModalItem.influenceIdName + "<br>星级:" + this.editModalItem.starNum + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.starId)) return "工单星级ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "工单星级状态",
            message: this.editModalItem.businessIdName + "/" + this.editModalItem.influenceIdName + "<br>星级:" + this.editModalItem.starNum + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.starStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.starStatus = this.editModalItem.starStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.starId)) return "工单星级ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //influenceId搜索字段
    private influenceIdLevelSearchField: any = [
        { field: "influence_name", name: "名称" },
        { field: "influence_remark", name: "说明" },
    ];
    //influenceId层级选择器打开
    public influenceIdLevelModalOpen() {
        (this.$refs.influenceIdLevel as any).openModal();
    }
    //influenceId层级选择器提交
    public async influenceIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().influenceId : item;
        this.tableRequestItem.lockItem.influenceId.value = curItem.value;
        this.tableRequestItem.lockItem.influenceId.title = curItem.title;
        this.tableRequestItem.lockItem.influenceId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //businessId搜索字段
    private businessIdLevelSearchField: any = [
        { field: "business_name", name: "名称" },
        { field: "business_remark", name: "说明" },
    ];
    //businessId层级选择器打开
    public businessIdLevelModalOpen() {
        (this.$refs.businessIdLevel as any).openModal();
    }
    //businessId层级选择器提交
    public async businessIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().businessId : item;
        this.tableRequestItem.lockItem.businessId.value = curItem.value;
        this.tableRequestItem.lockItem.businessId.title = curItem.title;
        this.tableRequestItem.lockItem.businessId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get businessIdSelectApi() {
        return {
            api: (query: any) => BusinessApi.idSelect(query),
            parent: "business_parent_id",
            status: "business_status",
            name: "business_name",
            id: "business_id",
            cid: this.editModalItem.businessId,
            extend: true,
        };
    }

    get influenceIdSelectApi() {
        return {
            api: (query: any) => InfluenceApi.idSelect(query),
            parent: "influence_parent_id",
            status: "influence_status",
            name: "influence_name",
            id: "influence_id",
            cid: this.editModalItem.influenceId,
            extend: true,
        };
    }
}
