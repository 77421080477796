import { Options, Vue } from "vue-class-component";
import MenuItem from "@/modules/role-menu-view/entity/MenuItem";

@Options({
    name: "app-sub-menu-item",
    props: {
        modelValue: Object,
        menuItem: Object,
        change: Function,
        left: Number,
    },
    emits: ["update:modelValue"],
})
export default class SubMenuItem extends Vue {
    private menuItem: MenuItem;
    private modelValue: any;

    get value() {
        return this.modelValue;
    }

    set value(newVal: any) {
        this.$emit("update:modelValue", newVal);
    }

    public mounted(): void {
        //console.log(this.menuItem.type);
    }
}
