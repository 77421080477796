export default class ProjectTypeTableItem {
    projectTypeId = 0;
    projectTypeStatus = 0;
    projectTypeParentId = 0;
    projectTypeParentName = "";
    projectTypeSortId = 0;
    projectTypeName = "";
    projectModelId = "";
    projectModelRouterCode = "";
    projectModelRouterName = "";
    projectTypeRemark = "";
    createTime = "";
    createName = "";
    modifyTime = "";
    modifyName = "";
}
