export default class RoleTableItem {
    roleId = 0;
    roleStatus = 0;
    orgTypeId = 0;
    orgTypeIdName = "";
    roleSortId = 0;
    roleName = "";
    roleRemark = "";
    roleTotal = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
