import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import BatchTableItem from "@/entity/attendance/duty/BatchTableItam";
import API from "@/api/attendance/duty/batch";
import { ElMessageBox } from "element-plus";

@Options({
    name: "app-attendance-duty-batch",
})
export default class Batch extends BaseTableMixins<BatchTableItem> implements Partial<BaseTableInterface<BatchTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new BatchTableItem();
        this.editModalItem = new BatchTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new BatchTableItem();
        this.editModalItem = new BatchTableItem();
        this.editModalItem.batchStatus = 1;
        this.editModalTitle = "添加批次";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.batchName)) return "批次名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.batchStatus)) return "批次状态不能为空！";
        return null;
    }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改批次 (" + this.editModalItem.batchName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.batchId)) return "批次ID不能为空！";
        return this.addSubmitChecking();
    }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除批次",
            message: "批次名称: " + this.editModalItem.batchName + "<br>请确认删除？",
        };
    }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.batchId)) return "批次ID不能为空！";
        return null;
    }
    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "批次排序";
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "批次状态",
            message: "批次: " + this.editModalItem.batchName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.batchStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.batchStatus = this.editModalItem.batchStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.batchId)) return "批次ID不能为空！";
        return null;
    }

    private btnDelBatch(item: BatchTableItem) {
        const batchId = item.batchId;
        ElMessageBox.confirm("是否要删除这条批次", "注意", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
                this.isLoading = true;
                API.delBatchOne({ batchId })
                    .then((response) => {
                        console.log(response.data);
                        this.toast.success("删除成功");
                        this.getList(0);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            })
            .catch(() => {});
    }
}
