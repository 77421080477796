import requests from "@/utils/axios";
import baseApi from "@/api/baseApi";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/system/master/role",
    // 获取角色菜单
    roleMenu(query: any): Promise<any> {
        return requests.post(this.modulesUrl + "/roleMenu", query);
    },
    // 保存角色菜单
    roleMenuSave(query: any): Promise<any> {
        return requests.post(this.modulesUrl + "/roleMenuSave", query);
    },
};
