import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-common-form-mixins",
    props: {
        modelValue: {
            type: [String, Number, Array, Object],
            default: (): any => {
                return null;
            },
        },
        mode: {
            type: String,
            default: "DESIGN",
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue"],
})
export default class ComponentMixins extends BaseFunction {
    private modelValue: any;
    get _value() {
        return this.modelValue;
    }
    set _value(val) {
        this.$emit("update:modelValue", val);
    }
}
