import { Options } from "vue-class-component";
import BaseTableInterface from "./BaseTableInterface";
import BaseFunction from "./BaseFunction";
import DropdownSearch from "@/modules/dropdown-search/dropdown-search.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import TablePage from "@/modules/table-page/table-page.vue";
import TableRequestItem from "@/entity/TableRequestItem";
import TablePageItems from "@/entity/TablePageItems";
import axios, { AxiosRequestConfig } from "axios";
import { httpTimeout, HttpUrl } from "@/utils/axios";
import { decrypt } from "@/utils/cryptojs";
import store from "@/store";

@Options({
    name: "app-base-table",
    components: {
        "app-dropdown-search": DropdownSearch,
        "app-draggable-listview": DraggableListview,
        "app-table-page": TablePage,
    },
})
export default class BaseTable<T> extends BaseFunction {
    protected isLoading = false;
    protected editModalType = "";
    protected editModalTitle = "";
    protected editModalSubmitButtonName = "";
    protected editTableItem: T = null;
    protected editModalItem: T = null;
    protected baseTableInterface: Partial<BaseTableInterface<T>> = {};
    protected API: any = null;

    protected dateTimeValue = "";
    protected dateTimeShortcuts = [
        {
            text: "今天",
            value: () => {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                const end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                start.setHours(0, 0, 0, 0);
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "昨天",
            value: () => {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
                const end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
                start.setHours(0, 0, 0, 0);
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "前天",
            value: () => {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
                const end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
                start.setHours(0, 0, 0, 0);
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "本周",
            value: () => {
                const now = new Date();
                const day = now.getDate();
                const week = now.getDay();
                const start = new Date(now.getFullYear(), now.getMonth(), day - week + 1);
                const end = new Date(now.getFullYear(), now.getMonth(), day - week + 7);
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "上周",
            value: () => {
                const now = new Date();
                const day = now.getDate();
                const week = now.getDay();
                const start = new Date(now.getFullYear(), now.getMonth(), day - (week + 7) + 1);
                const end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "本月",
            value: () => {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth(), 1);
                const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "上月",
            value: () => {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                const end = new Date(now.getFullYear(), now.getMonth(), 0);
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "最近3天",
            value: () => {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
                const end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "最近7天",
            value: () => {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);
                const end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "最近30天",
            value: () => {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29);
                const end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
        {
            text: "最近3个月",
            value: () => {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 89);
                const end = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                end.setHours(23, 59, 59, 999);
                return [start, end];
            },
        },
    ];

    protected setInterface(baseTableInterface: Partial<BaseTableInterface<T>>): void {
        this.baseTableInterface = baseTableInterface;
        this.API = baseTableInterface.getApi();
    }

    protected addModalOpen(): void {
        this.editModalType = "add";
        this.editTableItem = null;
        this.editModalItem = Object.assign({}, null);
        this.editModalTitle = "添加";
        this.editModalSubmitButtonName = "添加";
        if (this.baseTableInterface.addModalSetting) this.baseTableInterface.addModalSetting();
        (this.$refs.editModal as any).openModal();
    }

    protected modModalOpen(item: T): void {
        this.editModalType = "mod";
        this.editTableItem = item;
        this.editModalItem = Object.assign({}, item);
        this.editModalTitle = "修改";
        this.editModalSubmitButtonName = "修改";
        if (this.baseTableInterface.modModalSetting) this.baseTableInterface.modModalSetting();
        (this.$refs.editModal as any).openModal();
    }

    protected async editModalSubmit(): Promise<boolean> {
        if (this.editModalType == "add") {
            return await this.editModalAdd();
        } else if (this.editModalType == "mod") {
            return await this.editModalMod();
        }
    }

    //计算表格列数
    protected getTableTrLength(that: any) {
        if (that) {
            const tableTh = (that as HTMLElement).getElementsByTagName("th");
            if (tableTh) {
                return tableTh.length;
            } else {
                return 20;
            }
        } else {
            return 20;
        }
    }

    //搜索功能开始
    protected tableRequestItem: TableRequestItem = new TableRequestItem();
    protected searchCancel() {
        if (this.baseTableInterface.getLockItem) this.tableRequestItem.lockItem = this.baseTableInterface.getLockItem();
        this.tableRequestItem.search = false;
        this.tableRequestItem.searchField = "all";
        this.tableRequestItem.searchKey = "";
        this.tableRequestItem.dateTime = null;
        this.getList(1);
    }
    protected searchFunction(filed: string) {
        if (filed == "export") {
            this.getExport();
            return;
        } else if (filed == "refresh") {
            this.getList(1);
            return;
        } else if (filed == "search") {
            this.tableRequestItem.search = true;
            this.getList(1);
            return;
        }

        if (this.$tools.isEmpty(this.tableRequestItem.searchKey) && this.$tools.isEmpty(this.tableRequestItem.dateTime)) {
            this.searchCancel();
        } else {
            this.tableRequestItem.search = true;
            this.tableRequestItem.searchField = filed;
            this.getList(1);
        }
    }
    //搜索功能结束

    //列表功能开始
    protected tablePageItems: TablePageItems = new TablePageItems();
    protected tableItems: Array<T> = [];
    protected getList(page: number): any {
        if (page > 0) this.tableRequestItem.page = page;
        this.tableRequestItem.pageSize = this.getTableRows;
        let request = {};
        if (this.baseTableInterface.requestItem) {
            request = Object.assign({}, this.tableRequestItem, this.baseTableInterface.requestItem());
        } else {
            request = Object.assign({}, this.tableRequestItem);
        }
        this.isLoading = true;
        this.API.list(request)
            .then((res: any) => {
                this.tablePageItems.total = res.data.total;
                this.tablePageItems.pages = res.data.pages;
                this.tablePageItems.current = res.data.current;
                this.tablePageItems.size = res.data.size;
                //console.log(this.tableItems);
                if (this.baseTableInterface.listCallback) {
                    this.baseTableInterface.listCallback(res.data.records);
                } else {
                    this.tableItems = res.data.records;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
    //导出功能
    protected getExport(): any {
        this.isLoading = true;
        axios
            .create({ baseURL: HttpUrl, timeout: httpTimeout, headers: { "Content-Type": "application/json", authorization: decrypt(this.$store.getters["auth/token"]) } })
            .post(this.API.exportUrl(), Object.assign({}, this.tableRequestItem, { export: true, title: this.getTableTitle }), <AxiosRequestConfig>{ responseType: "blob" })
            .then((response) => {
                //console.log(response);
                response.data.text().then((text: any) => {
                    //console.log(text);
                    //接受token
                    const token = response.headers["authorization"];
                    if (token) store.dispatch("auth/setToken", token);
                    //解析是否是错误提示
                    const res = this.$tools.parseJSON(text);
                    if (res) {
                        this.toast.error(res.msg);
                        return;
                    }
                    //获取文件名称
                    let filename = "运维平台数据导出" + new Date().getTime() + ".xlsx";
                    if ("content-disposition" in response.headers) {
                        const content_disposition = response.headers["content-disposition"];
                        filename = content_disposition.split("filename=")[1];
                        filename = filename.split(";")[0];
                        filename = filename.replaceAll('"', "");
                        filename = decodeURI(filename);
                    }
                    //下载导出文件
                    const blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
                    if (window.navigator.msSaveOrOpenBlob) {
                        // 兼容IE10及以上版本
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                    }
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
    //获取表格标题栏
    get getTableTitle(): any {
        const titleArray: any = {};
        const tableTh = (this.$refs.tableTr as any).getElementsByTagName("th");
        for (let i = 0; i < tableTh.length; i++) {
            const th = tableTh[i];
            const field = (th.getAttribute("field") + "").trim();
            const title = (th.innerText + "").trim();
            if (field && field != "null") titleArray[field] = title;
        }
        return titleArray;
    }
    //列表功能结束

    //添加功能开始
    protected async editModalAdd(): Promise<boolean> {
        if (this.baseTableInterface.addSubmitChecking) {
            const addModalChecking = this.baseTableInterface.addSubmitChecking();
            if (!this.$tools.isEmpty(addModalChecking)) {
                this.toast.error(addModalChecking);
                return Promise.reject(false);
            }
        }
        await this.API.add(this.editModalItem).then((res: any) => {
            if (this.baseTableInterface.addModalCallback) this.baseTableInterface.addModalCallback(res);
            this.toast.success(res.msg);
            this.getList(0);
        });
        return Promise.resolve(true);
    }
    //添加功能结束

    //修改功能开始
    protected async editModalMod(): Promise<boolean> {
        if (this.baseTableInterface.modSubmitChecking) {
            const modModalChecking = this.baseTableInterface.modSubmitChecking();
            if (!this.$tools.isEmpty(modModalChecking)) {
                this.toast.error(modModalChecking);
                return Promise.reject(false);
            }
        }
        await this.API.mod(this.editModalItem).then((res: any) => {
            if (this.baseTableInterface.modModalCallback) this.baseTableInterface.modModalCallback(res);
            this.toast.success(res.msg);
            this.getList(0);
        });
        return Promise.resolve(true);
    }
    //修改功能结束

    //删除功能开始
    protected delAlert(item: T) {
        this.editTableItem = item;
        this.editModalItem = Object.assign({}, item);
        const delAlertConfig = this.baseTableInterface.delAlertConfig
            ? this.baseTableInterface.delAlertConfig()
            : {
                  type: "okAndNo",
                  title: "删除",
                  message: "请确认删除？",
              };
        delAlertConfig.submitButtonName = "删除";
        (this.$refs.delModal as any).openAlert(delAlertConfig, async () => {
            if (this.baseTableInterface.delAlertSetting) this.baseTableInterface.delAlertSetting();
            if (this.baseTableInterface.delSubmitChecking) {
                const delAlertChecking = this.baseTableInterface.delSubmitChecking();
                if (!this.$tools.isEmpty(delAlertChecking)) {
                    this.toast.error(delAlertChecking);
                    return Promise.reject(false);
                }
            }
            await this.API.del(this.editModalItem).then((res: any) => {
                if (this.baseTableInterface.delAlertCallback) this.baseTableInterface.delAlertCallback(res);
                this.toast.success(res.msg);
                this.getList(0);
            });
            return Promise.resolve(true);
        });
    }
    //删除功能结束

    //状态功能开始
    protected async toggleStatus(item: T) {
        this.editTableItem = item;
        this.editModalItem = Object.assign({}, item);

        if (this.baseTableInterface.toggleStatusSetting) this.baseTableInterface.toggleStatusSetting();
        if (this.baseTableInterface.toggleStatusSubmitChecking) {
            const toggleStatusChecking = this.baseTableInterface.toggleStatusSubmitChecking();
            if (!this.$tools.isEmpty(toggleStatusChecking)) {
                this.toast.error(toggleStatusChecking);
                return;
            }
        }

        const statusAlertConfig = this.baseTableInterface.statusAlertConfig
            ? this.baseTableInterface.statusAlertConfig()
            : {
                  title: "状态",
                  message: "请确认修改状态？",
                  closeButtonName: "取消",
                  submitButtonName: "提交",
              };

        this.$vueAlert(statusAlertConfig)
            .then(async () => {
                this.isLoading = true;
                await this.API.status(this.editModalItem)
                    .then((res: any) => {
                        if (this.baseTableInterface.toggleStatusCallback) this.baseTableInterface.toggleStatusCallback(res);
                        this.toast.success(res.msg);
                        this.getList(0);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            })
            .catch(() => {
                this.isLoading = false;
            });
    }
    //状态功能结束

    //排序功能开始
    protected editSortBaseTitle = "排序";
    protected editSortTitle: string = this.editSortBaseTitle;
    protected editSortList: any = [];
    //获取排序数据
    protected sortListView(item: T) {
        this.editTableItem = item;
        this.editModalItem = Object.assign({}, item);

        if (this.baseTableInterface.sortModalSetting) this.baseTableInterface.sortModalSetting();
        if (this.baseTableInterface.sortSubmitChecking) {
            const sortModalChecking = this.baseTableInterface.sortSubmitChecking();
            if (!this.$tools.isEmpty(sortModalChecking)) {
                this.toast.error(sortModalChecking);
                return Promise.reject(false);
            }
        }
        this.isLoading = true;
        this.API.sort(item)
            .then((res: any) => {
                this.editSortTitle = this.editSortBaseTitle + ": " + res.data.pName;
                this.editSortList = res.data.list;
                //console.log(this.editSortList);
                (this.$refs.sortModal as any).openModal();
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    //排序功能结束
    //排序保存功能开始
    protected async tableSortSubmit(): Promise<boolean> {
        if (this.editSortList < 1) {
            this.toast.error("排序数据不能为空！");
            return Promise.reject(false);
        }
        await this.API.sortSave(this.editSortList).then((res: any) => {
            this.toast.success(res.msg);
            this.getList(0);
        });
        return Promise.resolve(true);
    }
    //排序保存功能结束

    //表格显示行数
    get getTableRows() {
        return this.$store.getters["ui/tableRows"];
    }
}
