
import { Options } from "vue-class-component";
import InsertButton from "../../../components/InsertButton/InsertButton.vue";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-nodes-node",
    components: { InsertButton },
    props: {
        //是否为根节点
        isRoot: {
            type: Boolean,
            default: false,
        },
        //是否显示节点体
        show: {
            type: Boolean,
            default: true,
        },
        //节点内容区域文字
        content: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "标题",
        },
        placeholder: {
            type: String,
            default: "请设置",
        },
        //节点体左侧图标
        leftIcon: {
            type: String,
            default: undefined,
        },
        //头部图标
        headerIcon: {
            type: String,
            default: "",
        },
        //头部背景色
        headerBgc: {
            type: String,
            default: "#576a95",
        },
        //是否显示错误状态
        showError: {
            type: Boolean,
            default: false,
        },
        errorInfo: {
            type: String,
            default: "无信息",
        },
    },
    emits: ["selected", "delNode", "insertNode"],
})
export default class Node extends BaseFunction {
    get diagramMode(): any {
        return this.$store.getters["flowable/diagramMode"];
    }
    set diagramMode(val: any) {
        this.$store.dispatch("flowable/setDiagramMode", val).then();
    }
}
