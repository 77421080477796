import { Options, Vue } from "vue-class-component";
import Modal from "@/components/modal/modal.vue";
import DropdownSearch from "@/modules/dropdown-search/dropdown-search.vue";
import SubMenuItem from "@/modules/role-menu-view/sub-menu-item/sub-menu-item.vue";
import MenuItem from "./entity/MenuItem";
import API from "@/api/system/master/role";
import { useToast } from "vue-toastification";

@Options({
    name: "app-role-menu-view",
    components: {
        "app-modal": Modal,
        "app-dropdown-search": DropdownSearch,
        "app-sub-menu-item": SubMenuItem,
    },
    // watch: {
    //     menuSelected: {
    //         immediate: true,
    //         deep: true,
    //         handler(newVal: any) {
    //             console.log(newVal);
    //         }
    //     }
    // },
    emits: ["update:modelValue"],
})
export default class RoleMenuView extends Vue {
    private menuSelectModalElement: any;
    protected isLoading = false;
    protected toast = useToast();
    private tabId = 0;
    private roleItem: any = {};

    public setTab(value: number) {
        this.tabId = value;
    }

    public async mounted(): Promise<void> {
        this.menuSelectModalElement = this.$refs.menuSelectModal;
    }

    protected menuSelected: any = {};
    protected menuItems: Array<MenuItem> = [];
    protected getList(): void {
        this.isLoading = true;
        API.roleMenu({ roleId: this.roleItem.roleId })
            .then((res: any) => {
                this.menuItems = res.data.menuItems;
                this.menuSelected = res.data.menuSelected;
                //console.log(this.menuItems);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public openModal(roleItem: any) {
        this.tabId = 0;
        this.menuSelected = [];
        this.menuItems = [];
        this.roleItem = roleItem;
        this.getList();
        this.menuSelectModalElement.openModal();
    }

    public closeModal() {
        this.menuSelectModalElement.closeModal();
    }

    public toggleModal() {
        this.menuSelectModalElement.toggleModal();
    }

    //选择变更事件
    public onCheckChange(event: any, type: number) {
        const modelElement = document.getElementById("menuSelectModal") as HTMLElement;
        const currentTarget: HTMLElement = event.currentTarget;
        const checked: boolean = event.target.checked;
        const path = currentTarget.getAttribute("data-path");

        if (type === 2) {
            const selectElement = modelElement.querySelectorAll('[data-path^="' + path + '"]');
            for (let i = 0; i < selectElement.length; i++) {
                const curPath = selectElement[i].getAttribute("data-path");
                this.menuSelected[curPath] = checked;
            }
        }

        //模块事件已完成
        //菜单事件待完成

        // console.log(path);
        // // console.log(event.currentTarget);
        // console.log(type);
        // console.log(checked);
    }

    protected async submitFunction(): Promise<boolean> {
        const modelElement = document.getElementById("menuSelectModal") as HTMLElement;
        const items = Object.assign({}, this.menuSelected);
        const menuSelectedItems: string[] = [];
        //去除无效菜单
        for (const path in items) {
            if (path.length < 1) continue;
            const selectElement = modelElement.querySelector('[data-path="' + path + '"]');
            if (selectElement && items[path]) menuSelectedItems.push(path);
        }

        if (this.$tools.isEmpty(menuSelectedItems)) {
            this.toast.error("请选择角色菜单！");
            return Promise.reject(false);
        }
        await API.roleMenuSave({
            roleId: this.roleItem.roleId,
            menuSelected: menuSelectedItems,
        }).then((res: any) => {
            this.toast.success(res.msg);
        });
        return Promise.resolve(true);
    }
}
