export default class StarTableItem {
    starId = 0;
    starStatus = 0;
    businessId = 0;
    businessIdName = "";
    influenceId = 0;
    influenceIdName = "";
    starNum = "0";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
